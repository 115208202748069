import React from 'react'
import { Highlight } from 'components/Highlight'
import { Box, Card, Chip, Typography, useTheme } from '@mui/material'
import CheckIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { isEmpty } from 'lodash'
import FlexContainer from 'components/FlexContainer'
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined'
import WarningIcon from '@mui/icons-material/Warning'
import InfoIcon from '@mui/icons-material/Info'

export const Status = {
  OUT_OF_STOCK: {
    text: 'Agotado',
    icon: DoDisturbOutlinedIcon,
    iconColor: 'error',
  },
  LOW_STOCK: {
    text: 'Pocas unidades',
    icon: WarningIcon,
    iconColor: 'warning',
  },
  HEALTHY_STOCK: {
    text: 'Stock sano',
    icon: CheckIcon,
    iconColor: 'default',
  },
  EXCESSIVE_STOCK: {
    text: 'Stock excesivo',
    icon: InfoIcon,
    iconColor: 'info',
  },
}

export const getLabel = product => {
  if (isEmpty(product)) return ''

  const option = Status[product?.stock_status]

  const isOk = product?.stock_status === 'HEALTHY_STOCK'
  const outOfStock = product?.stock <= 0

  // if (isEmpty(option) || isOk)
  //   return (
  //     <Typography
  //       variant="body2"
  //       color="text.primary"
  //       sx={{
  //         '& b': {
  //           fontWeight: '500',
  //         },
  //       }}
  //     >
  //       {'Stock:'} <b>{product?.stock}</b>
  //     </Typography>
  //   )

  let label = ''

  const { icon, iconColor } = option

  const Icon = icon

  if (outOfStock) {
    label = (
      <FlexContainer justifyContent="flex-start">
        <Icon fontSize="small" color={iconColor} />
        <Typography sx={{ ml: 1, fontWeight: '600' }} variant="caption">
          Agotado
        </Typography>
      </FlexContainer>
    )
  } else {
    label = (
      <FlexContainer justifyContent="flex-start">
        {!isOk ? <Icon fontSize="small" color={iconColor} /> : null}
        <Typography
          sx={{
            ml: 1,
            fontWeight: isOk ? '400' : '600',
          }}
          variant="caption"
        >
          {`Stock: ${product?.stock}`}
        </Typography>
      </FlexContainer>
    )
  }

  return (
    <Chip variant={'outlined'} color={iconColor} size="small" label={label} />
  )
}

export default function ProductItemSearch({
  isActive,
  parseAmount = () => {},
  product,
  onClick = () => {},
}) {
  const theme = useTheme()

  const currentPrice = parseAmount({
    value: product?.current_price,
    showCode: false,
  })

  const outOfStock = product?.stock <= 0

  const activeBgColor = 'hsl(210, 100%, 96%)'
  const inactiveBgColor = 'rgba(246, 247, 248, 0.4)'
  const disableBgColor = 'rgba(238, 78, 79, 0.1)'

  const activeBorder = '1px solid hsl(210, 100%, 70%)'
  const inactiveBorder = '1px solid rgba(232, 234, 238, 0.5)'
  const disableBorder = `1px solid ${theme.palette.error.light}`

  const bgColor = isActive
    ? activeBgColor
    : outOfStock
    ? disableBgColor
    : inactiveBgColor
  const border = isActive
    ? activeBorder
    : outOfStock
    ? disableBorder
    : inactiveBorder

  const stockLabel = getLabel(product)

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: bgColor,
        border: border,
        padding: '2px 16px',
        mb: 1,
        cursor: 'pointer',
        opacity: outOfStock ? '0.6' : '1',
        '&:hover': {
          backgroundColor: isActive
            ? activeBgColor
            : theme.palette.action.hover,
          border: isActive
            ? activeBorder
            : `1px solid ${theme.palette.divider}`,
        },
      }}
      //   onLongPress={onLongPress}
      onClick={() => {
        const hit = product

        // sendClickEvent(hit)

        onClick(product)
      }}
    >
      <Box>
        <Typography
          variant="subtitle1"
          color="text.primary"
          sx={{ fontWeight: '500' }}
        >
          <Highlight hit={product} attribute="product_name" />
        </Typography>
        <Typography variant="body2" color="text.primary">
          {`${product?.pharmaceutical_form} ${product?.laboratory}`}
        </Typography>

        <Box
          sx={{
            mt: 2,
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              '& b': {
                color: theme.palette.text.primary,
                fontWeight: '500',
              },
            }}
          >
            {'Ubicación:'} <b>{product?.location}</b>
          </Typography>
          <Typography
            sx={{
              ml: 3,
              '& b': {
                color: theme.palette.text.primary,
                fontWeight: '500',
              },
            }}
            variant="body2"
            color="text.primary"
            align="right"
          >
            {'Precio:'} <b>{currentPrice}</b>
          </Typography>
          {stockLabel}
        </Box>
      </Box>
    </Card>
  )
}
