import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import Form from './Form'
import ReceiveBatch from 'features/orders/components/ReceiveBatch'
import useEditProduct from './useEditProduct'
import { Controller } from 'react-hook-form'
import LaboratorySelector from 'components/LaboratorySelector'
import LocationSelector from 'components/LocationSelector'
import PharmaceuticalFormSelector from 'components/PharmaceuticalFormSelector'
import FlexContainer from 'components/FlexContainer'
import { Search } from '@mui/icons-material'
import GoogleIcon from '@mui/icons-material/Google'

const inputSpace = 0.5
const blockSpace = 2
const groupSpace = 3
const inputVariant = 'outlined'
const inputSize = 'medium'

export default function EditProductModal({ product, onClose }) {
  const { state, parseAmount, getFormProps, errors, watch, setValue } =
    useEditProduct({ product })

  const productData = product

  console.log({ productData })

  const hasError = key => {
    return Boolean(errors[key])
  }

  return (
    <Dialog
      keepMounted
      open={true}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          minHeight: '700px',
          height: '700px',
        },
      }}
    >
      <DialogTitle>Editar producto</DialogTitle>
      <Divider />
      <DialogContent>
        <Controller
          {...getFormProps({
            name: 'product_name',
            rules: { required: true },
          })}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ mt: 2 }}
              fullWidth
              size={inputSize}
              variant={inputVariant}
              label="Escanear código de barras"
              error={hasError('product_name')}
              helperText={hasError('product_name') ? 'Campo requerido' : ''}
            />
          )}
        />
        {/* 
        <Box sx={{ my: groupSpace }}>
          <PharmaceuticalFormSelector
            size={inputSize}
            variant={inputVariant}
            value={watch('pharmaceutical_form')}
            setValue={value => {
              setValue('pharmaceutical_form', value)
            }}
          />
        </Box>

        <LaboratorySelector
          size={inputSize}
          variant={inputVariant}
          value={watch('laboratory')}
          setValue={value => {
            setValue('laboratory', value)
          }}
        />
        <Box sx={{ my: groupSpace }}>
          <LocationSelector
            size={inputSize}
            variant={inputVariant}
            value={watch('location')}
            setValue={value => {
              setValue('location', value)
            }}
          />
        </Box> */}

        <Typography variant="subtitle1" sx={{ mt: groupSpace, mb: inputSpace }}>
          Stock
        </Typography>

        <FlexContainer mb={blockSpace}>
          <TextField
            fullWidth
            variant={inputVariant}
            size={inputSize}
            inputMode="number"
            label="Stock minimo"
            {...getFormProps({ name: 'manual_min_stock' })}
          />

          <Box sx={{ width: '24px' }}></Box>

          <TextField
            fullWidth
            variant={inputVariant}
            size={inputSize}
            label="Stock maximo"
            inputMode="number"
            {...getFormProps({ name: 'manual_max_stock' })}
          />
        </FlexContainer>

        <Typography variant="subtitle1" sx={{ mt: groupSpace, mb: inputSpace }}>
          Categorías y usos
        </Typography>
        <Box
        // sx={theme => ({
        //   borderRadius: 1,
        //   backgroundColor: theme.palette.action.hover,
        //   padding: 1,
        // })}
        >
          <FlexContainer justifyContent="flex-start">
            <Typography
              variant="subtitle1"
              sx={{ mt: groupSpace, mb: inputSpace }}
            >
              Categorías y usos
            </Typography>
            {/* <IconButton
              size="small"
              component="button"
              href=""
              sx={theme => ({ background: theme.palette.primary2[100] })}
            >
              <Search color="primary" fontSize="14px" />
            </IconButton> */}
          </FlexContainer>
          <Controller
            {...getFormProps({
              name: 'category',
              rules: { required: true },
            })}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ mb: groupSpace }}
                multiline={true}
                fullWidth
                size={inputSize}
                variant={inputVariant}
                label="Categoria"
                error={hasError('category')}
                helperText={hasError('category') ? 'Campo requerido' : ''}
              />
            )}
          />
          <Controller
            {...getFormProps({
              name: 'serves_to',
              rules: { required: true },
            })}
            render={({ field }) => (
              <TextField
                {...field}
                multiline={true}
                rows={4}
                fullWidth
                size={inputSize}
                variant={inputVariant}
                label="¿Para qué sirve este producto?"
                error={hasError('serves_to')}
                helperText={hasError('serves_to') ? 'Campo requerido' : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        height: '100px',
                        maxHeight: '100px',
                        alignItems: 'flex-start',
                      }}
                    >
                      <IconButton
                        size="small"
                        component="button"
                        href=""
                        sx={theme => ({
                          background: theme.palette.neutral[100],
                        })}
                      >
                        <GoogleIcon color="primary" fontSize="14px" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" size="large">
          Actualizar producto
        </Button>
      </DialogActions>
    </Dialog>
  )
}
