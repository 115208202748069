import React, { useMemo, useState } from 'react'
import usePharmacy from 'hooks/usePharmacy'
import { db } from 'config/firebase'
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  writeBatch,
} from 'firebase/firestore'
import { toast } from 'sonner'
import FlexContainer from 'components/FlexContainer'
import { getLabel, Status } from 'components/ChipFilters/StockFilter'
import { useHits, usePagination } from 'react-instantsearch'
import useParsers from 'hooks/useParsers'
import { Box, Card, Typography, useTheme } from '@mui/material'
import { isEmpty, isNumber } from 'lodash'
import { numberIsValid } from 'utils/numbers/numberIsValid'
import { getStockStatus } from 'services/inventory/getStockStatus'
import ProductOptions from '../ProductOptions'
import { formatDistanceToNow } from 'date-fns'
import { formatDate } from 'utils/formatDate'
import { es } from 'date-fns/locale/es'
import ExpirationChip from 'components/ExpirationChip'
import { getProductStatistics } from 'services/inventory/getProductStatistics'

const transformItems = items => {
  return items.map(item => ({
    ...item,
    id: item.objectID,
    stock_raw: item.stock,
    max_stock: item.manual_max_stock,
    min_stock: item.manual_min_stock,
    product_name_raw: item?.product_name,
    expiration_date_timestamp: item?.expiration_date,
    current_price_number: item?.current_price,
  }))
}

const productsDB = [
  '765446110323',
  '0003',
  '18901790698707',
  '24968420512421',
  '0056',
  '764600111664',
  '18901790701537',
  '7434002554293',
  '8901872002678',
  '0090',
  '7750929001536',
  '18901790704118',
  '18901790698370',
  '18901790697397',
  '7613035945142',
  '0021',
  '18901790703630',
  '8901790550640',
  '0028',
  '7432002903844',
  '7431001110116',
  '011418112100',
  '7410031491990',
  '769229090819',
  '743000004301',
  '6971087040200',
  '0133',
  '7431001114046',
  '0004',
  '0108',
  '8436024613117',
  '7441031500863',
  '7404004200528',
  '7501019034183',
  '7431008000090',
  '7702031642718',
  '7434001141647',
  '0025',
  '18901790698660',
  '0095',
  '8436026215616',
  '7702035432339',
  '7501101620027',
  '7441163300751',
  '0102',
  '7401078920062',
  '7501054504870',
  '18901790698219',
  '764600244218',
  '7502268547387',
  '18901790703081',
  '0081',
  '7703202220643',
  'X001WS32SR',
  '18901790550135',
  '7432002900652',
  '6972544370854',
  '7703763220106',
  '7703763002023',
  '756969001847',
  '7431007701714',
  '7431007700694',
  '7410002800967',
  '7410002010533',
  '7406048001031',
  '7401071400608',
  '18901790700103',
  '18901790698325',
  '026964080025',
  '0105',
  '0097',
  '0019',
  '7431001100216',
  '7401104600692',
]

export default function useAllProductsTable() {
  const { hits } = useHits({ transformItems })
  const { parseAmount } = useParsers()
  const { selectedPharmacy } = usePharmacy()
  const [archivedProducts, setArchivedProducts] = useState([])
  const theme = useTheme()

  const {
    currentRefinement,
    nbHits,
    nbPages,
    isFirstPage,
    isLastPage,
    refine,
  } = usePagination()

  const columnBaseProperties = {
    disableColumnMenu: true,
    sortable: true,
    resizable: true,
    editable: false,
  }

  const updateProducts = async () => {
    try {
      const pharmacy_ID = selectedPharmacy?.pharmacy_ID

      if (!selectedPharmacy) return

      console.log('### Start Add new properties')

      const q = query(
        collection(db, 'products'),
        where('pharmacy_ID', '==', pharmacy_ID)
      )

      const productsQuerySnapshot = await getDocs(q)

      if (productsQuerySnapshot?.empty) return

      // Create a new batch instance
      const batchInstance = writeBatch(db)

      const productsDocuments = []

      //Obtener los documentos
      productsQuerySnapshot.forEach(documentSnapshot => {
        const data = documentSnapshot.data()

        productsDocuments.push({
          product_ID: documentSnapshot.id,
          ...data,
          ref: documentSnapshot?.ref,
        })
      })

      const updatedProducs1 = []

      for await (const data of productsDocuments) {
        //Obtener estadisticas de venta del producto
        // const stats = await getProductStatistics({
        //   pharmacy_ID: data?.pharmacy_ID,
        //   product_ID: data?.product_ID,
        //   stock: data?.stock,
        // })
        // const averagePerDay = stats?.count?.day
        // const averagePerWeek = stats?.count?.week
        // const averagePerMonth = stats?.count?.month
        // const SoldLast3Months = stats?.count?.total
        // const soldOutIn = stats?.soldOutIn?.days
        // const soldOutInStatus = stats?.soldOutIn?.status

        const stock = data?.stock

        const manual_min_stock = numberIsValid(Number(data?.manual_min_stock))
          ? Number(data?.manual_min_stock)
          : 0

        const manual_max_stock = numberIsValid(Number(data?.manual_max_stock))
          ? Number(data?.manual_max_stock)
          : 0

        const low_stock = stock < manual_min_stock
        const excessive_stock = stock > manual_max_stock

        const stock_status = getStockStatus({
          stock,
          low_stock,
          excessive_stock,
        })

        const archived = productsDB.includes(data?.product_code)

        if (archived) {
          updatedProducs1.push(data?.product_code)
        }
        // console.log({
        //   productEddited: {
        //     product_ID: data.product_ID,
        //     product_name: data.product_name,
        //     low_stock,
        //     excessive_stock,
        //     stock_status,
        //     average_per_day: averagePerDay,
        //     average_per_week: averagePerWeek,
        //     average_per_month: averagePerMonth,
        //     sold_last_3months: SoldLast3Months,
        //     sold_out_in: soldOutIn,
        //     sold_outin_status: soldOutInStatus,
        //   },
        // })

        batchInstance.update(data?.ref, {
          low_stock,
          excessive_stock,
          stock_status,
          archived,
          // average_per_day: averagePerDay,
          // average_per_week: averagePerWeek,
          // average_per_month: averagePerMonth,
          // sold_last_3months: SoldLast3Months,
          // sold_out_in: soldOutIn,
          // sold_outin_status: soldOutInStatus,
        })
      }

      console.log({ codes: updatedProducs1, counter: updatedProducs1?.length })

      return batchInstance.commit()
    } catch (err) {
      console.error(err)
    } finally {
      console.log('### END new properties')
    }
  }

  const archiveProduct = async product_ID => {
    const batch = writeBatch(db)

    const productRef = doc(db, 'products', product_ID)

    batch.update(productRef, {
      archived: true,
    })

    await batch.commit()

    toast.success('El producto fue archivado exitosamente')
    setArchivedProducts(prevValue => [...prevValue, product_ID])
  }

  const unarchiveProduct = async product_ID => {
    const batch = writeBatch(db)

    const productRef = doc(db, 'products', product_ID)

    batch.update(productRef, {
      archived: false,
    })

    await batch.commit()

    toast.success('El producto fue Desarchivado exitosamente')
    setArchivedProducts(prevValue => [...prevValue, product_ID])
  }

  const columns = [
    {
      ...columnBaseProperties,
      field: 'product_name',
      headerName: 'Producto',
      width: 250,
      display: 'flex',
      renderCell: ({ row }) => {
        return (
          <Box>
            <Typography variant="body2" color="text.primary">
              {row?.product_name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`${row?.pharmaceutical_form} ${row?.laboratory}`}
              {/* {row?.product_code} */}
            </Typography>
          </Box>
        )
      },
    },
    {
      ...columnBaseProperties,
      field: 'product_code',
      headerName: 'Código',
      width: 140,
    },
    {
      ...columnBaseProperties,
      field: 'product_name_raw',
      headerName: 'Nombre del producto',
      width: 250,
    },
    {
      ...columnBaseProperties,
      field: 'pharmaceutical_form',
      headerName: 'Forma',
      width: 150,
      hide: true,
    },
    {
      ...columnBaseProperties,
      field: 'laboratory',
      headerName: 'Laboratorio',
      width: 150,
    },

    {
      ...columnBaseProperties,
      field: 'min_stock',
      headerName: 'Stock min',
      type: 'number',
      width: 100,
    },
    {
      ...columnBaseProperties,
      field: 'stock_raw',
      headerName: 'Stock actual',
      type: 'number',
      width: 100,
    },
    {
      ...columnBaseProperties,
      field: 'max_stock',
      headerName: 'Stock max',
      type: 'number',
      width: 100,
    },
    {
      ...columnBaseProperties,
      field: 'current_price_number',
      headerName: 'Precio de venta',
      width: 110,
    },
    {
      ...columnBaseProperties,
      field: 'sold_units',
      headerName: 'Vendido',
      type: 'number',
      width: 110,
    },
    {
      ...columnBaseProperties,
      field: 'current_price',
      headerName: 'Precio',
      width: 100,
      valueGetter: (value, row) =>
        parseAmount({
          value: row?.current_price,
        }),
    },
    {
      ...columnBaseProperties,
      field: 'stock_status',
      headerName: 'Status',
      width: 180,
      display: 'flex',
      alignItems: 'center',
      renderCell: ({ row }) => {
        const option = Status[row.stock_status]

        return getLabel(option)
      },
    },
    {
      ...columnBaseProperties,
      field: 'stock',
      headerName: 'Stock',
      width: 200,
      alignItems: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        const option = Status[row.stock_status]

        return (
          <Card
            variant="outlined"
            sx={{
              padding: 1,
              border: 'none',
              backgroundColor: 'transparent',
            }}
          >
            {/* {getLabel(option)} */}

            <FlexContainer>
              <FlexContainer flexDirection="column">
                <Typography
                  variant="caption"
                  align="center"
                  color="text.secondary"
                >
                  Min
                </Typography>
                <Typography
                  sx={{ ml: 0.5 }}
                  variant="body2"
                  align="center"
                  color="text.secondary"
                >
                  {row.manual_min_stock}
                </Typography>
              </FlexContainer>
              <FlexContainer ml={1} mr={1} flexDirection="column">
                <Typography variant="caption" align="center">
                  Actual
                </Typography>
                <Typography
                  sx={{ ml: 0.5, fontWeight: '700' }}
                  variant="body2"
                  align="center"
                >
                  {row.stock}
                </Typography>
              </FlexContainer>
              <FlexContainer flexDirection="column">
                <Typography
                  variant="caption"
                  align="center"
                  color="text.secondary"
                >
                  Max
                </Typography>
                <Typography
                  sx={{ ml: 0.5 }}
                  variant="body2"
                  align="center"
                  color="text.secondary"
                >
                  {row.manual_max_stock}
                </Typography>
              </FlexContainer>
            </FlexContainer>
          </Card>
        )
      },
    },
    {
      ...columnBaseProperties,
      field: 'average_per_week',
      headerName: 'Ventas por semana',
      type: 'number',
      width: 150,
    },
    {
      ...columnBaseProperties,
      field: 'sold_out_in',
      headerName: 'Se agotará en',
      type: 'number',
      width: 150,
      display: 'flex',
      alignItems: 'center',
      renderCell: ({ row }) => {
        return (
          <Typography variant="body2" color="text.primary">
            {`${row?.sold_out_in} ${row?.sold_out_in === 1 ? 'dia' : 'dias'}`}
          </Typography>
        )
      },
    },
    {
      ...columnBaseProperties,
      field: 'expiration_date_timestamp',
      headerName: 'Vencimiento',
      width: 150,
      display: 'flex',
    },
    {
      ...columnBaseProperties,
      field: 'expiration_date',
      headerName: 'Fecha de vencimiento',
      width: 280,
      display: 'flex',
      renderCell: ({ row }) => {
        const hasDate = isNumber(row?.expiration_date)

        const dateRelative = hasDate
          ? formatDistanceToNow(row?.expiration_date, {
              addSuffix: true,
              locale: es,
            })
          : ''

        const expiration_date = hasDate
          ? formatDate(row?.expiration_date, 'MMM yyyy')
          : ''

        const label = hasDate ? `${expiration_date} (${dateRelative})` : 'N/D'

        return (
          <ExpirationChip label={label} expirationDate={row?.expiration_date} />
        )
      },
    },
    {
      ...columnBaseProperties,
      field: 'location',
      headerName: 'Ubicación',
      width: 100,
      display: 'flex',
    },
    {
      ...columnBaseProperties,
      field: 'action',
      headerName: 'Acción',
      width: 100,
      alignItems: 'center',
      renderCell: ({ row }) => {
        return (
          <ProductOptions
            data={row}
            archiveProduct={product => {
              archiveProduct(product?.objectID)
            }}
            unarchiveProduct={product => {
              unarchiveProduct(product?.objectID)
            }}
          />
        )
      },
    },
  ]

  const handleChangePage = payload => {
    const page = payload

    refine(page)
  }

  const filteredProducts = useMemo(() => {
    if (isEmpty(archivedProducts)) return hits

    return hits?.filter(hit => {
      const isArchived = archivedProducts?.includes(hit?.objectID)

      return !isArchived
    })
  }, [hits, archivedProducts])

  return {
    handleChangePage,
    columns,
    archiveProduct,
    hits,
    currentRefinement,
    nbHits,
    nbPages,
    isFirstPage,
    isLastPage,
    filteredProducts,
    updateProducts,
    archivedProducts,
  }
}
