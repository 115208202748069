import React from 'react'
import { Box, Button, Pagination, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import useAllProductsTable from './useAllProductsTable'
import FlexContainer from 'components/FlexContainer'
import useModal from 'hooks/useModal'
import ProductDetail from '../ProductDetail'
import EditProductModal from '../EditProductModal'

export default function AllProductsTable({
  hitsPerPage,
  setHitsPerPage,
  sortModel,
}) {
  const {
    handleChangePage,
    columns,
    currentRefinement,
    nbHits,
    nbPages,
    filteredProducts,
    updateProducts,
    archivedProducts,
  } = useAllProductsTable()

  const productDetail = useModal()
  const editProductModal = useModal()

  return (
    <Box>
      {/* <Button color="error" variant="contained" onClick={updateProducts}>
        Actualizar invenario
      </Button> */}
      <FlexContainer mt={2} mb={0}>
        <Box>
          <Typography variant="body2">{`Resultados: ${
            nbHits - archivedProducts.length
          } Productos`}</Typography>
        </Box>

        <Pagination
          count={nbPages}
          shape="rounded"
          page={currentRefinement + 1}
          onChange={(_, payload) => {
            const page = payload - 1

            handleChangePage(page)
          }}
        />
      </FlexContainer>
      <DataGrid
        // hideFooter={true}
        disableColumnFilter
        slots={{ toolbar: GridToolbar }}
        localeText={{
          columnsManagementShowHideAllText: 'Mostrar todo/Ocultar todo',
          columnsManagementReset: 'Restablecer',
          columnsManagementSearchTitle: 'Buscar columna',
          toolbarColumns: 'Columnas',
          toolbarColumnsLabel: 'Columnas',
          toolbarExportLabel: 'Exportar',
          toolbarExport: 'Exportar',
          toolbarExportPrint: 'Imprimir',
          toolbarExportCSV: 'Descargar CSV',
          toolbarDensity: 'Tamaño',
          toolbarDensityLabel: 'Tamaño',
          toolbarDensityCompact: 'Pequeño',
          toolbarDensityStandard: 'Mediano',
          toolbarDensityComfortable: 'Grande',
          // footerRowSelected: 'Selecciones para',
        }}
        rows={filteredProducts}
        columns={columns}
        autoHeight
        rowHeight={70}
        pageSizeOptions={[15, 50, 100]}
        paginationMode="server"
        paginationModel={{
          page: currentRefinement,
          pageSize: hitsPerPage,
        }}
        rowCount={nbHits}
        onPaginationModelChange={payload => {
          const pageSize = payload?.pageSize

          if (pageSize !== hitsPerPage) {
            setHitsPerPage(payload?.pageSize)
            handleChangePage(1)
          } else {
            handleChangePage(payload?.page)
          }
        }}
        checkboxSelection
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              sold_units: false,
              product_name_raw: false,
              pharmaceutical_form: false,
              laboratory: false,
              min_stock: false,
              max_stock: false,
              stock_raw: false,
              expiration_date_timestamp: false,
              current_price_number: false,
            },
          },
        }}
        sx={{
          border: 'none',
        }}
        sortModel={sortModel}
        // onRowDoubleClick={response => {
        //   editProductModal.open({ data: { product: response?.row } })
        // }}
      />

      {productDetail.visible ? (
        <ProductDetail
          product_ID={productDetail?.data?.product_ID}
          onClose={productDetail.close}
        />
      ) : null}
      {editProductModal.visible ? (
        <EditProductModal
          product={editProductModal?.data?.product}
          onClose={editProductModal.close}
        />
      ) : null}
    </Box>
  )
}
