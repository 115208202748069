import { Box, Card, CardContent, Typography } from '@mui/material'
import Container from 'components/Container'
import FlexContainer from 'components/FlexContainer'
import Wrapper from 'components/Wrapper'
import ListOfInvoices from 'features/home/components/ListOfInvoices'
import ProductsSold from 'features/home/components/ProductsSold'
import SalesSummary from 'features/home/components/SalesSummary'
import React from 'react'

export default function Home() {
  return (
    <Container>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          gridGap: '16px',
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Resumen de ventas
            </Typography>
            <SalesSummary />

            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
              Ventas recientes
            </Typography>

            <ListOfInvoices />
          </CardContent>
        </Card>
        <ProductsSold />
      </Box>
    </Container>
  )
}
