import useParsers from 'hooks/useParsers'
import usePharmacy from 'hooks/usePharmacy'
import { useEffect, useState } from 'react'
import { getAverageSalesPerDay } from 'services/inventory/getAverageSalesPerDay'
import { searchProductInDB } from 'services/searchProductInDB'
import { isNumber, isDate } from 'lodash'
import { getUserById } from 'utils/getUserById'
import { getAverageSalesPerWeek } from 'services/inventory/getAverageSalesPerWeek'
import { getStockStatus } from 'services/inventory/getStockStatus'
import { getLabel, Status } from 'components/ChipFilters/StockFilter'
import { useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

const INITIAL_VALUE = {
  product_code: '',
  generated_product_code: '',
  product_name: '',
  laboratory: '',
  pharmaceutical_form: '',
  manual_min_stock: '',
  manual_max_stock: '',
  location: '',
}

export default function useEditProduct({ product }) {
  const [state, setState] = useState({ error: false, loading: true, data: {} })
  const { parseAmount } = useParsers()
  const { selectedPharmacy, users } = usePharmacy()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: INITIAL_VALUE,
  })

  const getFormProps = ({ name, rules = { required: true } }) => {
    return {
      name,
      control,
      errors,
      rules,
    }
  }

  useEffect(() => {
    if (isEmpty(product)) return

    setValue('product_name', product?.product_name)
    setValue('pharmaceutical_form', product?.pharmaceutical_form)
    setValue('laboratory', product?.laboratory)
    setValue('location', product?.location)

    setValue('manual_min_stock', product?.manual_min_stock)
    setValue('manual_max_stock', product?.manual_max_stock)
  }, [product])

  return {
    state,
    parseAmount,
    getFormProps,
    errors,
    watch,
    setValue,
  }
}
