import React from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'

export default function CardResult({ label, value, footer = '' }) {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="h4" sx={{ mt: 1, fontWeight: 'bold' }}>
          {value}
        </Typography>

        <Typography variant="caption">{footer}</Typography>
      </CardContent>
    </Card>
  )
}
