import React from 'react'
import CardResult from '../CardResult'
import { Box } from '@mui/material'
import useSalesSummary from './useSalesSummary'
import useParsers from 'hooks/useParsers'
import { floor } from 'lodash'

export default function SalesSummary() {
  const { state } = useSalesSummary()
  const { parseAmount } = useParsers()

  const { totalCount, totalRevenue, averageTicket } = state?.data

  const TOTAL_MINUTES = 720

  const avgCustomers = floor(TOTAL_MINUTES / totalCount, 2)
  const avgRevenue = totalRevenue / 12

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '16px',
      }}
    >
      <CardResult
        label="🙋🏻‍♂️ Clientes atendidos"
        value={totalCount}
        footer={`Un cliente cada: ${avgCustomers} minutos`}
      />
      <CardResult
        label="💵 Total vendido"
        value={parseAmount({ value: totalRevenue })}
        footer={`${parseAmount({ value: avgRevenue })} cada hora`}
      />
      <CardResult
        label="🎟️ Ticket promedio"
        value={parseAmount({ value: averageTicket })}
        footer={'En promedio, cada cliente compra'}
      />
    </Box>
  )
}
