import React from 'react'
import {
  Box,
  Card,
  List,
  ListItem,
  ListItemButton,
  Typography,
  useTheme,
} from '@mui/material'
import useProductsSold from './useProductsSold'
import useParsers from 'hooks/useParsers'
import { cropText } from 'utils/cropText'
import { amber, red } from '@mui/material/colors'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import useModal from 'hooks/useModal'
import ProductDetail from 'features/products/components/ProductDetail'

export default function ProductsSold() {
  const { state } = useProductsSold()
  const { parseAmount } = useParsers()
  const theme = useTheme()
  const productDetail = useModal()

  const products = state.data

  const Status = {
    OUT_OF_STOCK: {
      text: 'Agotado',
      icon: null,
      iconColor: 'error',
      color: red[100],
    },
    LOW_STOCK: {
      text: 'Pocas unidades',
      icon: null,
      iconColor: 'warning',
      color: amber[100],
    },
    HEALTHY_STOCK: {
      text: 'Stock sano',
      icon: null,
      color: theme.palette.action.hover,
    },
    EXCESSIVE_STOCK: {
      text: 'Stock excesivo',
      icon: null,
      color: theme.palette.action.hover,
    },
  }

  return (
    <Card>
      <Typography sx={{ my: 2, mx: 2 }} variant="h6">
        Productos vendidos
      </Typography>
      <List
        sx={{
          overflow: 'auto',
          height: 'calc(100vh - 170px)',
        }}
      >
        {products?.map((product, index) => {
          const count = `Ventas: ${product?.count}`
          const revenue = `Total: ${parseAmount({ value: product?.amount })}`

          const description = `${count} - ${revenue}`

          const position = index + 1

          const laboratory = cropText(product?.laboratory)
          const pharmaceuticalForm = cropText(product?.pharmaceutical_form)

          const displayName = `${cropText(
            product?.product_name
          )} ${pharmaceuticalForm} ${laboratory}`

          const stockStatus = Status[product.stock_status]

          const days = product?.stats?.soldOutIn?.days || 0

          return (
            <ListItemButton
              key={product?.product_ID}
              divider
              onClick={() => {
                productDetail.open({
                  data: { product_ID: product?.product_code },
                })
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyItems: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={theme => ({
                    backgroundColor: 'transparent',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  })}
                >
                  <Typography variant="h6">{`#${position}`}</Typography>
                </Box>
                <Box sx={{ mx: 2 }}>
                  <Typography
                    variant="body2"
                    color={'text.primary'}
                    sx={{ mb: 1 }}
                  >
                    {displayName}
                  </Typography>
                  <Typography variant="body2" color={'text.secondary'}>
                    {description}
                  </Typography>
                </Box>

                <Tooltip
                  title={
                    product.stock === 0
                      ? 'Agotado'
                      : `Se agotara en: ${days} dia${days === 1 ? '' : 's'}`
                  }
                  placement="top-end"
                >
                  <Box
                    sx={theme => ({
                      ml: 'auto',
                      backgroundColor: stockStatus?.color,
                      borderRadius: 1,
                      padding: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    })}
                  >
                    <Typography variant="caption">Stock</Typography>
                    <Typography variant="subtitle2">{product.stock}</Typography>
                  </Box>
                </Tooltip>
              </Box>

              {/* <ListItemText primary={displayName} secondary={description} /> */}
            </ListItemButton>
          )
        })}
      </List>
      {productDetail.visible ? (
        <ProductDetail
          product_ID={productDetail?.data?.product_ID}
          onClose={productDetail.close}
        />
      ) : null}
    </Card>
  )
}
