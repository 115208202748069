export const AUTH = '/auth'
export const LOGIN = '/auth/login'
export const CREATE_ACCOUNT = '/auth/create-account'

export const ADMIN = '/admin'
export const HOME = '/admin/inicio'
export const CREATE_INVOICE = '/admin/crear-factura'
export const RECEIVE_ORDER = '/admin/recibir-pedido'
export const ALL_PRODUCTS = '/admin/productos'

export const TRAVELS_PER_MONTH = '/admin/travels/months'

export const TRAVELS = '/admin/travels'
export const ADD_TRAVEL = '/admin/travels/add'

export const LOGOUT = '/admin/logout'
