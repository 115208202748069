import React from 'react'
import useListOfInvoices from './useListOfInvoices'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { Box } from '@mui/material'

export default function ListOfInvoices() {
  const { columns, state } = useListOfInvoices()

  return (
    <Box sx={{ height: 'calc(100vh - 400px)' }}>
      <DataGrid
        columns={columns}
        rows={state.data}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
      />
    </Box>
  )
}
