import { useEffect, useState } from 'react'
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { db } from 'config/firebase'
import usePharmacy from 'hooks/usePharmacy'
import { sumBy } from 'lodash'
import { getUserById } from 'utils/getUserById'
import useParsers from 'hooks/useParsers'
import { formatDate } from 'utils/formatDate'

export default function useListOfInvoices() {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: [],
  })

  const { selectedPharmacy, users } = usePharmacy()
  const { parseAmount } = useParsers()

  const columns = [
    {
      field: 'id',
      headerName: 'id',
    },
    {
      field: 'transaction_number',
      headerName: '# Transacción',
      width: 130,
    },
    {
      width: 200,
      field: 'datetime',
      headerName: 'Fecha',
    },
    {
      field: 'user',
      headerName: 'Usuario',
      width: 130,
    },
    {
      field: 'type_of_invoice',
      headerName: 'Tipo de factura',
    },
    {
      field: 'total_paid',
      headerName: 'Total',
      valueGetter: (value, row) =>
        parseAmount({
          value: row?.total_paid,
          showCode: false,
        }),
    },
    {
      width: 300,
      field: 'description',
      headerName: 'Descripción',
    },
  ]

  const getData = async querySnapshot => {
    try {
      if (querySnapshot?.empty) {
        setState({
          error: false,
          loading: false,
          data: [],
        })
      } else {
        const _data = []

        querySnapshot.forEach(documentSnapshot => {
          const response = documentSnapshot.data()

          const _item = {
            docID: documentSnapshot.id,
            ...response,
          }

          _data.push(_item)
        })

        console.log({ invoices: _data })

        const rows = _data.map(row => {
          const currentUser = getUserById({ users, user_ID: row.created_by })

          const userName = currentUser?.user_name

          return {
            ...row,
            id: row.docID,
            user: userName,
            datetime: formatDate(row?.time, 'd MMM yyyy hh:mm aa'),
          }
        })

        setState({
          error: false,
          loading: false,
          data: rows,
        })
      }
    } catch (error) {
      console.error('Error al obtener el resumen de ventas del dia')
      console.error(error)
    }
  }

  useEffect(() => {
    try {
      const pharmacy_ID = selectedPharmacy?.pharmacy_ID

      if (!pharmacy_ID) return

      const date = formatDate(new Date(), 'yyyy-MM-dd')

      const q = query(
        collection(db, 'invoices'),
        where('pharmacy_ID', '==', pharmacy_ID),
        where('date', '==', date),
        orderBy('time', 'desc')
      )

      const subscriber = onSnapshot(q, getData, error => {
        console.error(error)
      })

      return () => subscriber()
    } catch (e) {
      console.error(e)
    }
  }, [selectedPharmacy?.pharmacy_ID])

  return { state, getData, columns }
}
